<template>
	<div class="padding-top-10 edit-contain">
		<top :bgWhite='true'></top>
		<div class="contents  padding-bottom-20 ">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/my' }"><span class="fs16">首页</span></el-breadcrumb-item>
				<el-breadcrumb-item><span class="fs16">举报</span></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="bg-white margin-top-20 padding-lr-50 padding-tb-20">
				<div class="">
					<p>
						<span>投诉</span>
						<span class="text-blue">@{{user.user_nickname|| '--'}}</span>
						<span>的动态</span>
					</p>
					<div class="flex align-center margin-top-24">
						<p class=" text-blue_dark">@{{user.user_nickname|| '--'}}：<p class="text-cut w80" v-html="dataInfo.body"></p></p>
					</div>
				</div>
				<div class="margin-top-64">
					<p class="fs24 bold">请填写举报信息</p>
					<p class="fs18  margin-top-24">举报内容</p>
				</div>
				<div class="margin-top-20">
					<el-input type="textarea"  rows="6" placeholder="请输入举报内容" v-model="body"></el-input>
				</div>
				<div class="margin-top-96 flex justify-center">
					<el-button type="danger" round @click="submitForm">提交</el-button>
				</div>
			</div>
		</div>
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	import {
		provinceAndCityData,
		regionData,
		provinceAndCityDataPlus,
		regionDataPlus,
		CodeToText,
		TextToCode
	} from 'element-china-area-data'
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		name: 'editArticle',
		components: {
			bottom,
			top
		},
		data() {
			return {
				aid:'',
				body:'',
				user:{},
				dataInfo:{},
				is_click:false
			}
		},
		mounted() {
			this.aid = this.$route.query.aid;
			this.getArticleDetail()
		},
		created() {
			
		},
		methods: {
			submitForm(){
				if(this.is_click){
					this.$message.info('您操作的太频繁啦');
					return;
				}
				this.is_click = true;
				setTimeout(()=> {
					this.is_click = false;
				}, 1500);
				if(!this.body.trim()){
					this.$message.error('请输入举报内容');
					return;
				}
				this.ajax('post', '/v1/5f69f1b268699', {
					be_report_article_id:this.aid,
					remark:this.body
				}, res => {
					if (res.code == 1) {
						this.$message.success('提交成功');
						this.body = '';
						setTimeout(()=>{
							this.$router.go(-1)
						},800)
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 获取文章详情
			getArticleDetail() {
				this.ajax('post', '/v1/613afc809f5e3', {
					article_id: this.$route.query.aid
				}, res => {
					if (res.code == 1) {
						this.user = res.data.user;
						this.dataInfo = res.data
					} else {
						this.$message.error(res.msg);
					}
				})
			}
		}
	}
</script>

<style scoped>
	.edit-contain {
		width: 100vw;
		height: 100vh;
		background-color: #F8F8F8;
		overflow: auto;
	}
	.contents {
		width: 80%;
		height: auto;
		margin: 80px auto;
	}
	/deep/.el-input__inner {
		border: none;
		text-align: right;
	}
	.text-blue{
		color: #098EF0;
	}
	.text-blue_dark{
		color: #46546F;
	}
</style>
